import React from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";

const RootesqueWrapper = styled.div`
  align-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.backgroundColor};
  box-sizing: border-box;
  color: ${(props) => props.theme.text.primary};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  justify-items: center;
  min-height: 100vh;
  min-width: 100vw;
  padding: 1rem;
  width: 100%;
  z-index: 0;
`;

const Container = () => {
  return (
    <RootesqueWrapper>
      <Header />
      <Outlet />
      <Footer />
    </RootesqueWrapper>
  );
};

export default Container;
