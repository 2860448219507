import PropTypes from "prop-types";
import { useMediaQuery } from "@react-hook/media-query";
import { useCookies } from "react-cookie";
import GlobalStyle from "../styles/GlobalStyle";
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { ThemeProvider } from "styled-components";
import WiThemeDark from "../styles/WiThemeDark";
// import WiThemeLight from "../styles/WiThemeLight";
import themeReducer from "./themeReducer";

const MetaThemeContext = createContext(null);

const useMetaThemeContext = () => useContext(MetaThemeContext);

const MetaThemeProvider = ({ children }) => {
  // reference for first render
  const { current: isFirstRender } = useRef(true);

  // styled-components theme object
  const [theme, dispatchTheme] = useReducer(themeReducer, WiThemeDark);

  // cookies hook
  const [cookies, setCookie] = useCookies(["theme"]);

  // query for OS theme mode preference
  const prefersLightMode = useMediaQuery("(prefers-color-scheme: light)");

  // check for OS theme preference and existing cookie on first render
  // TODO: default to dark for  OS/browsers that don't inherit dark/light preferences
  useEffect(() => {
    if (isFirstRender) {
      // if existing cookie, follow cookie value
      if (Object.hasOwn(cookies, "theme")) {
        if (cookies?.theme === "light") {
          dispatchTheme({ type: "goLight" });
          return;
        } else if (cookies?.theme === "dark") {
          dispatchTheme({ type: "goDark" });
          return;
        } else {
          console.error(`unexpected cookies.theme value: `, cookies.theme);
        }
      } else {
        // if no cookie, check user agent preference via media query
        if (prefersLightMode) {
          dispatchTheme({ type: "goLight" });
          return;
        } else {
          dispatchTheme({ type: "goDark" });
          return;
        }
      }
    }
  }, [prefersLightMode, isFirstRender, cookies]);

  // user toggles theme mode by setting the theme cookie
  const toggleThemeMode = () => {
    if (theme?.mode === "dark") {
      setCookie("theme", "light", { path: "/", sameSite: "lax" });
      // setTheme(WiThemeLight);
    } else {
      setCookie("theme", "dark", { path: "/" });
    }
  };

  return (
    <MetaThemeContext.Provider
      value={{ isDark: theme?.mode === "dark", toggleThemeMode, theme }}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </MetaThemeContext.Provider>
  );
};

MetaThemeProvider.propTypes = {
  children: PropTypes.node,
};

export { MetaThemeProvider, useMetaThemeContext };
