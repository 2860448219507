/* Simple Icons currently in use across site */
import React, { memo } from "react";
import SimpleIconFactory from "./SimpleIconFactory";

/* Individual Imports */
import {
  siAwsamplify,
  siD3dotjs,
  siExpress,
  siGithub,
  siGooglecloud,
  siLinkedin,
  siMongodb,
  siMui,
  siNodedotjs,
  siReact,
  siReactrouter,
  siSocketdotio,
  siStyledcomponents,
  siStylus,
  siTwitter,
  siVite,
  siVuedotjs,
  siWebpack,
} from "simple-icons/icons";

export const AwsAmplifyLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siAwsamplify} />;
});
AwsAmplifyLogoSimple.displayName = "AwsAmplifyLogoSimple";

export const D3LogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siD3dotjs} />;
});
D3LogoSimple.displayName = "D3LogoSimple";

export const ExpressLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siExpress} />;
});
ExpressLogoSimple.displayName = "ExpressLogoSimple";

export const GithubLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siGithub} />;
});
GithubLogoSimple.displayName = "GithubLogoSimple";

export const GoogleCloudLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siGooglecloud} />;
});
GoogleCloudLogoSimple.displayName = "GoogleCloudLogoSimple";

export const LinkedInLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siLinkedin} />;
});
LinkedInLogoSimple.displayName = "LinkedInLogoSimple";

export const MaterialUiLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siMui} />;
});
MaterialUiLogoSimple.displayName = "MaterialUiLogoSimple";

export const MongoDbLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siMongodb} />;
});
MongoDbLogoSimple.displayName = "MongoDbLogoSimple";

export const NodeDotJsLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siNodedotjs} />;
});
NodeDotJsLogoSimple.displayName = "NodeDotJsLogoSimple";

export const ReactLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siReact} />;
});
ReactLogoSimple.displayName = "ReactLogoSimple";

export const ReactRouterLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siReactrouter} />;
});
ReactRouterLogoSimple.displayName = "ReactRouterLogoSimple";

export const SocketDotIoLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siSocketdotio} />;
});
SocketDotIoLogoSimple.displayName = "SocketDotIoLogoSimple";

export const StyledComponentsLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siStyledcomponents} />;
});
StyledComponentsLogoSimple.displayName = "StyledComponentsLogoSimple";

export const StylusLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siStylus} />;
});
StylusLogoSimple.displayName = "StylusLogoSimple";

export const TwitterLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siTwitter} />;
});
TwitterLogoSimple.displayName = "TwitterLogoSimple";

export const ViteLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siVite} />;
});
ViteLogoSimple.displayName = "ViteLogoSimple";

export const VueLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siVuedotjs} />;
});
VueLogoSimple.displayName = "VueLogoSimple";

export const WebpackLogoSimple = memo((props) => {
  return <SimpleIconFactory {...props} siObject={siWebpack} />;
});
WebpackLogoSimple.displayName = "WebpackLogoSimple";
