import React from "react";
import styled from "styled-components";
import GradientDefOne from "./GradientDefOne";
import GradientAccentsLR from "./GradientAccentsLR";
import GradientAccents45 from "./GradientAccents45";

// wraps all of the SVG definitions into a single hidden div
// for tucking somewhere once per page (currently in the footer)

const HiddenDefsWrap = styled.div`
  /* display: none;  // breaks gradient defs */
  height: 0px;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  width: 100%;
`;

const Svg = styled.svg`
  width: 100%;
`;

export default function SvgDefs() {
  return (
    <HiddenDefsWrap>
      <GradientDefOne />{" "}
      <Svg xmlns="http://www.w3.org/2000/svg">
        <defs>
          <GradientAccentsLR />
          <GradientAccents45 />
        </defs>
      </Svg>
    </HiddenDefsWrap>
  );
}
