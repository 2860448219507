import { keyframes } from "styled-components";

const blurPulse = keyframes`
  from {
    filter: blur(1px);
  }

  to {
    filter: blur(2.5px);
  }
`;

export default blurPulse;
