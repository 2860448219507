import * as React from "react";
import { memo } from "react";
import PropTypes from "prop-types";

const SvgDocumentText = ({ options = {}, siObject = {}, ...props }) => {
  let {
    title = null,
    fill = "currentColor",
    desc = null,
    alt = null,
    width = "100%",
    height = "100%",
    useHex = false,
  } = options;
  const { title: siTitle, hex: siHex } = siObject;

  // title prefers HOC title -> siObject title -> null
  title = title ? title : siTitle ? siTitle : null;

  // fill defers to HOC useHex -> siObject hex -> black, else HOC fill -> "currentColor" (aka font color)
  fill = useHex ? (siHex ? siHex : "#000") : fill;

  // desc prefers HOC desc -> HOC alt -> siObject title -> null
  desc = desc ? desc : alt ? alt : title ? `${title} Icon` : null;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      height={height}
      {...props}
    >
      {title && <title>{title}</title>}
      {desc && <desc>{desc}</desc>}

      <path
        d="M428 224H288a48 48 0 0 1-48-48V36a4 4 0 0 0-4-4h-92a64 64 0 0 0-64 64v320a64 64 0 0 0 64 64h224a64 64 0 0 0 64-64V228a4 4 0 0 0-4-4zm-92 160H176a16 16 0 0 1 0-32h160a16 16 0 0 1 0 32zm0-80H176a16 16 0 0 1 0-32h160a16 16 0 0 1 0 32z"
        fill={fill}
      />
      <path
        d="M419.22 188.59 275.41 44.78a2 2 0 0 0-3.41 1.41V176a16 16 0 0 0 16 16h129.81a2 2 0 0 0 1.41-3.41z"
        fill={fill}
      />
    </svg>
  );
};

SvgDocumentText.propTypes = {
  options: PropTypes.object,
  siObject: PropTypes.object,
};
const Memo = memo(SvgDocumentText);
export default Memo;
