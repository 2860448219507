import PropTypes from "prop-types";
import * as React from "react";
import { memo } from "react";

/* Works with svgs originally sourced from https://simpleicons.org 
   which consistently have:
   - only one path element
   - informational object available via npm simple-icons
   - ex: import { siSimpleicons } from 'simple-icons/icons'
*/

/* icon Options object: 
  path, // only one path is supported
  title, // tile available in simple-icons object
  fill = "currentColor", // defaults inherits CSS color, loses to useHex
  desc = null,
  alt = null, // same as desc, catches my common error
  width = "100%", // default to fill div; for use in body, set to 1em 
  height = "100%", // default to fill div, for use in body, set to 1em 
  hex = null,
  useHex = false,
  ...props // passed in from higher level component
*/

const SimpleIconFactory = ({ options = {}, siObject, ...props }) => {
  let {
    path = null,
    title = null,
    fill = "currentColor",
    desc = null,
    alt = null,
    width = "100%",
    height = "100%",
    useHex = false,
  } = options;
  const { path: siPath, title: siTitle, hex: siHex } = siObject;

  // path prefers HOC path -> siObject path -> null
  path = siPath ? siPath : siObject?.path ? siObject.path : null;

  // title prefers HOC title -> siObject title -> null
  title = title ? title : siTitle ? siTitle : null;

  // fill defers to HOC useHex -> siObject hex -> black, else HOC fill -> "currentColor" (aka font color)
  fill = useHex ? (siHex ? siHex : "#000") : fill;

  // desc prefers HOC desc -> HOC alt -> siObject title -> null
  desc = desc ? desc : alt ? alt : title ? `${title} Icon` : null;

  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      {title && <title>{title}</title>}
      {desc && <desc>{desc}</desc>}
      {path && <path d={path} fill={fill} />}
    </svg>
  );
};

SimpleIconFactory.propTypes = {
  options: PropTypes.shape({
    alt: PropTypes.any,
    desc: PropTypes.any,
    fill: PropTypes.string,
    height: PropTypes.string,
    path: PropTypes.any,
    title: PropTypes.any,
    useHex: PropTypes.bool,
    width: PropTypes.string,
  }),
  siObject: PropTypes.shape({
    hex: PropTypes.any,
    path: PropTypes.any,
    title: PropTypes.any,
  }),
};

const Memo = memo(SimpleIconFactory);
export default Memo;
