import React from "react";
import Container from "./components/Container";
import Main from "./components/Main";
import Resume from "./components/Resume";
import { MetaThemeProvider } from "./contexts/MetaThemeContext";
import { Route, Routes } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
export const App = () => {
  return (
    <CookiesProvider>
      <MetaThemeProvider>
        <Routes>
          <Route element={<Container />}>
            <Route index element={<Main />} />
            <Route path="resume" element={<Resume />} />
            <Route path="*" element={<Main />} />
          </Route>
        </Routes>
      </MetaThemeProvider>
    </CookiesProvider>
  );
};
