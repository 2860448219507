import PropTypes from "prop-types";
import React from "react";
import { withTheme } from "styled-components";

export const GradientAccents45Id = `gradient-accents-45-fullpage`;
export const GradientAccents45Url = `url(#${GradientAccents45Id})`;

function GradientAccents45({ theme }) {
  return (
    <linearGradient
      x1="0"
      x2="100%"
      y2="100%"
      id={GradientAccents45Id}
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor={theme.accent1}></stop>
      <stop stopColor={theme.accent2} offset="100%" stopOpacity={1}></stop>
    </linearGradient>
  );
}

GradientAccents45.propTypes = {
  theme: PropTypes.shape({
    accent1: PropTypes.string,
    accent2: PropTypes.string,
  }),
};

export default withTheme(GradientAccents45);
