import { keyframes } from "styled-components";

const blurPulse = keyframes`
  from {
    filter: blur(0.1px);
  }

  to {
    filter: blur(0.5px);
  }
`;

export default blurPulse;
