import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import SvgDefs from "../../styles/SvgDefs";
import ArticleMain from "../common/ArticleMain";
import Link from "../common/Link";
const ccBy4Link = "https://creativecommons.org/licenses/by/4.0/";

// Note: presence of SvgDefs in footer means footer width must equal container width of any main content
const FineFeet = styled(ArticleMain)`
  align-items: center;
  display: flex;
  filter: opacity(0.6);
  flex-flow: column;
  font-size: 0.9rem;
  justify-content: center;
  justify-items: center;
  margin: 2rem 0 0rem;
  padding: 2rem 0 1rem;
  user-select: none;
`;

const AttributionLink = styled(Link)`
  color: ${({ theme }) => theme.text.primary};
`;

const Footer = ({ className }) => {
  return (
    <FineFeet as="footer" className={className}>
      <SvgDefs />
      <span>
        <AttributionLink href={ccBy4Link}>CC-BY</AttributionLink> Matthieu
        Pierce 2022
      </span>
    </FineFeet>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
