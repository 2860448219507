import PropTypes from "prop-types";
import React, { lazy, Suspense } from "react";
import styled from "styled-components";
const attributesImport = import("../Attributes/index.js");
const Attributes = lazy(() => attributesImport);
import PortfolioBlock from "../PortfolioBlock";
import ElsewhereLinks from "../ElsewhereLinks";
import Profile from "../Profile";

const MainMain = styled.main`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: ${({ theme }) => theme.mainMaxWidth};
`;

const ArticleFallback = styled.article`
  height: 16rem;
  width: 100%;
`;

export const Main = ({ className }) => {
  return (
    <MainMain className={className}>
      <Profile />
      <PortfolioBlock />
      <ElsewhereLinks />
      <Suspense fallback={<ArticleFallback />}>
        <Attributes />
      </Suspense>
    </MainMain>
  );
};

Main.propTypes = {
  className: PropTypes.string,
};

export default Main;
