import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import LinkListItem from "../LinkListItem";
import ArticleMain from "../common/ArticleMain";
import { GradientDefOneUrl } from "../../styles/SvgDefs/GradientDefOne";
import {
  GithubLogoSimple,
  LinkedInLogoSimple,
  TwitterLogoSimple,
} from "../Logos/SimpleIconsStore";

export const MainLinkList = styled.ul`
  align-content: center;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16ch, 1fr));
  list-style-type: none;
  margin: 0;
  max-width: 100%;
  padding: 0.2rem 0;
  width: 100%;
`;

const Site = styled.div`
  font-size: clamp(0.85rem, 1rem + 2vw, 1.2rem);
  font-weight: 600;
  margin: 0;
  padding: 0;
  padding-top: 0.4rem;
`;

const Username = styled.div`
  font-size: clamp(0.85rem, 2vw, 1rem);
  font-weight: 400;
  max-width: 100%;
`;

const ListItem = styled(LinkListItem)`
  padding: 0.5rem 0;
  a {
    display: flex;
    flex-direction: column;
  }
`;

const BigI = styled.div.attrs(({ options }) => ({
  height: options?.height || "2rem",
}))`
  display: grid;
  place-items: center;
  background-image: ${({ theme }) => theme.gradients.accents145};
  background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: ${({ height }) => `calc(${height} - 0.3rem)`};
  font-weight: 800;
  width: 100%;
  height: ${({ height }) => height};
  box-sizing: border-box;
`;

export default function ElsewhereLinks({
  heading = "Elsewhere",
  showHeading = true,
  iconHeight,
  className,
}) {
  const iconOptions = {
    fill: GradientDefOneUrl,
    height: iconHeight ? iconHeight : "2.2rem",
  };

  return (
    <ArticleMain className={className}>
      {showHeading && <h2>{heading}</h2>}
      <MainLinkList>
        <ListItem href="https://github.com/MatthieuPierce">
          <GithubLogoSimple options={iconOptions} />
          <Site>Github</Site>
          <Username>/matthieupierce</Username>
        </ListItem>

        <ListItem href="https://www.linkedin.com/in/matthieupierce/">
          <LinkedInLogoSimple options={iconOptions} />
          <Site>LinkedIn</Site>
          <Username>/in/matthieupierce</Username>{" "}
        </ListItem>

        <ListItem href="https://www.twitter.com/matthieupierce/">
          <TwitterLogoSimple options={iconOptions} />
          <Site>Twitter</Site>
          <Username>@matthieupierce</Username>{" "}
        </ListItem>

        <ListItem href="mailto:me@matthieupierce.com">
          <BigI options={iconOptions}>@</BigI>
          <Site>Email</Site>
          <Username>me@matthieupierce.com</Username>{" "}
        </ListItem>
      </MainLinkList>
    </ArticleMain>
  );
}

ElsewhereLinks.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  iconHeight: PropTypes.string,
  showHeading: PropTypes.bool,
};
