import PropTypes from "prop-types";
import React from "react";
import styled, { withTheme } from "styled-components";
import Billboard from "./Billboard";
import NavBar from "./NavBar";

const Header = ({ className }) => {
  return (
    <HeadHeader className={className}>
      <NavBar />
      <Billboard />
    </HeadHeader>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

const HeadHeader = styled.header`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  max-width: 100vw;
  width: 100%;
  z-index: 1;
`;

export default withTheme(Header);
