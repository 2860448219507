import React from "react";
import styled from "styled-components";
import ModeSplitLightDark from "../../media/icons/ModeSplitLightDark";
import ModalityLogo from "../../media/logos/ModalityLogo";
import ReactHookFormLogoSimple from "../../media/logos/ReactHookFormLogoSimple";
import { GradientDefOneUrl } from "../../styles/SvgDefs/GradientDefOne";
import Link from "../common/Link";
import IconsRow from "./IconsRow";
import Icon from "../Logos/IconWrap";
import ThemeToggleButton from "../common/ThemeToggleButton";
import ArticleMain from "../common/ArticleMain";

import {
  AwsAmplifyLogoSimple,
  ExpressLogoSimple,
  GoogleCloudLogoSimple,
  ReactLogoSimple,
  MaterialUiLogoSimple,
  MongoDbLogoSimple,
  NodeDotJsLogoSimple,
  ViteLogoSimple,
  ReactRouterLogoSimple,
  D3LogoSimple,
  SocketDotIoLogoSimple,
  StyledComponentsLogoSimple,
  StylusLogoSimple,
  WebpackLogoSimple,
  VueLogoSimple,
} from "../../components/Logos/SimpleIconsStore";

const Section = styled.section`
  padding: 0rem 0 3rem;
`;

const PortfolioLink = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: center;
  justify-items: center;
  padding: 0rem 0;
  h3 {
    font-size: 1.25rem;
    max-width: 100%;
    width: 95%;
    word-wrap: break-word;
  }
  text-align: center;
`;

const Ul = styled.ul`
  align-items: center;
  box-sizing: border-box;
  display: grid;
  gap: 1.2rem;
  justify-items: center;
  list-style: none;
  margin: 0;
  max-width: 100%;
  padding: 1rem 0 0;
  width: 100%;
`;

const Note = styled.li`
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
  justify-items: center;
  margin: 0;
  padding: 0.25rem 1rem 0.25rem 0.5rem;
  text-align: center;
  width: 100%;
`;

const iconOptions = { fill: GradientDefOneUrl };

export default function PortfolioBlock() {
  return (
    <ArticleMain>
      <h2>Portfolio</h2>
      <Section>
        <PortfolioLink
          href="https://www.countriesandcharacters.com"
          target="_blank"
        >
          <h3>Countries and Characters, my multiplayer storytelling game.</h3>
        </PortfolioLink>
        <Ul>
          <Note>
            <IconsRow>
              <Icon>
                <MongoDbLogoSimple options={iconOptions} />
              </Icon>
              <Icon>
                <ExpressLogoSimple options={iconOptions} />
              </Icon>
              <Icon>
                <ReactLogoSimple options={iconOptions} />
              </Icon>
              <Icon>
                <NodeDotJsLogoSimple options={iconOptions} />
              </Icon>
            </IconsRow>
            <p>
              Tabletop-style RPG aide built on a MERN stack:{" "}
              <Link href="https://www.mongodb.com/">MongoDB</Link>,{" "}
              <Link href="https://expressjs.com//">Express</Link>,{" "}
              <Link href="https://reactjs.org">React</Link>, and{" "}
              <Link href="https://nodejs.org/">Node</Link>, plus OAuth login via
              JWT, and a REST API.
            </p>
          </Note>
          <Note>
            <IconsRow>
              <Icon>
                <GoogleCloudLogoSimple options={iconOptions} />
              </Icon>
              <Icon>
                <WebpackLogoSimple options={iconOptions} />
              </Icon>
              <Icon>
                <SocketDotIoLogoSimple options={iconOptions} />
              </Icon>
              <ThemeToggleButton>
                <Icon>
                  <ModeSplitLightDark pathFill={GradientDefOneUrl} />
                </Icon>
              </ThemeToggleButton>
            </IconsRow>
            <p>
              Deployed to{" "}
              <Link href="https://cloud.google.com/appengine/">
                Google Cloud
              </Link>{" "}
              and bundled with{" "}
              <Link href="https://webpack.js.org/">Webpack</Link>. Features
              group chat via <Link href="https://socket.io/">Socket.io</Link>,{" "}
              light and dark themes, and nerdy fun.
            </p>
          </Note>
        </Ul>
      </Section>
      <Section>
        <PortfolioLink href="https://waveways.llc" target="_blank">
          <h3>Waveways LLC, a therapy practice in Portland, OR.</h3>
        </PortfolioLink>
        <Ul>
          <Note>
            <IconsRow>
              <Icon>
                <ReactLogoSimple options={iconOptions} />
              </Icon>
              <Icon>
                <MaterialUiLogoSimple options={iconOptions} />
              </Icon>
              <Icon>
                <ViteLogoSimple options={iconOptions} />
              </Icon>
            </IconsRow>
            <p>
              Consulted on brand styling and established this business{`'`} web
              presence with <Link href="https://reactjs.org">React</Link>,{" "}
              <Link href="https://mui.com">Material UI</Link>, and{" "}
              <Link href="https://vitejs.dev">Vite</Link>.
            </p>
          </Note>
          <Note>
            <IconsRow>
              <Icon>
                <AwsAmplifyLogoSimple options={iconOptions} />
              </Icon>
              <ThemeToggleButton>
                <Icon>
                  <ModeSplitLightDark pathFill={GradientDefOneUrl} />
                </Icon>
              </ThemeToggleButton>

              <Icon>
                <ReactHookFormLogoSimple pathFill={GradientDefOneUrl} />
              </Icon>
              <Icon>
                <ReactRouterLogoSimple options={iconOptions} />
              </Icon>
            </IconsRow>
            <p>
              Deployed to{" "}
              <Link href="https://aws.amazon.com/amplify">AWS via Amplify</Link>
              . Features light and dark themes,{" "}
              <Link href="https://react-hook-form.com/">React Hook Form</Link>,
              and <Link href="https://reactrouter.com">React Router</Link>.
            </p>
          </Note>
        </Ul>
      </Section>
      <Section>
        <PortfolioLink href="#" target="_self">
          <h3>matthieupierce.com</h3>
        </PortfolioLink>
        <Ul>
          <Note>
            <IconsRow>
              <Icon>
                <D3LogoSimple options={iconOptions} />
              </Icon>
              <Icon width="auto" height="100%">
                <StyledComponentsLogoSimple
                  options={{ ...iconOptions, width: "3rem" }}
                />
              </Icon>
              {/* <IconBgImage source={StyledComponentsLogoPng} /> */}
              <Icon>
                <WebpackLogoSimple options={iconOptions} />
              </Icon>
            </IconsRow>
            <p>
              Built with React, <Link href="https://d3js.org">D3</Link>,{" "}
              <Link href="https://styled-components.com/">
                styled-components
              </Link>
              , and a self-configured{" "}
              <Link href="https://webpack.js.org/">Webpack</Link>.{" "}
            </p>
          </Note>
        </Ul>
      </Section>
      <Section>
        <PortfolioLink href="https://docs.auxon.io" target="_blank">
          <h3>Auxon Corp, Modality Documentation</h3>
        </PortfolioLink>
        <Ul>
          <Note>
            <IconsRow>
              <Icon>
                <ModalityLogo pathFill={GradientDefOneUrl} />
              </Icon>
              <Icon height="100%">
                <StylusLogoSimple options={iconOptions} />
              </Icon>
              <Icon>
                <VueLogoSimple options={iconOptions} />
              </Icon>
            </IconsRow>
            <p>
              Strategic consulting for{" "}
              <Link href="https://docs.auxon.io">technical docs</Link>, and
              restyling via <Link href="https://stylus-lang.com"> Stylus</Link>{" "}
              and <Link href="https://vuepress.vuejs.org">VuePress</Link>{" "}
              plugins.
            </p>
          </Note>
        </Ul>
      </Section>
    </ArticleMain>
  );
}
