import React from "react";
import styled from "styled-components";
import NameCursive from "../../../media/svgr/NameCursive";

/* Name in Lights as plain (non-svg) h1 text */
// const NameInLightsH1 = styled.h1`
//   display: flex;
//   flex-direction: column;
//   color: ${({ theme }) => theme.text.primary};
//   font-size: clamp(5rem, calc(1rem + 12vmin), 10rem);
//   line-height: clamp(5rem, calc(1rem + 12vmin), 10rem);
//   padding: 4vmin 0 0 5vmin;
//   font-weight: 600;
//   font-family: ${({ theme }) => theme.text.fontFamilyHero};
//   transform: rotate(-15deg);
//   z-index: 4;
//   filter: blur(0.5px) drop-shadow(5px 5px 15px #ffffff66);
// `;

const NameWrap = styled.div`
  fill: ${({ theme }) => theme.text.primary};
  filter: blur(0.5px) drop-shadow(5px 5px 15px #ffffff66);
  overflow: hidden;
  stroke: ${({ theme }) => theme.text.primary};
  width: clamp(300px, 76%, 720px);
  z-index: 2;
`;

export default function NameInLights() {
  return (
    <NameWrap>
      <NameCursive width={"100%"} />
    </NameWrap>
  );
}
