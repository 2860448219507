import PropTypes from "prop-types";
import React from "react";
import { withTheme } from "styled-components";

export const GradientAccentsLRId = `gradient-accents-lr-fullpage`;
export const GradientAccentsLRUrl = `url(#${GradientAccentsLRId})`;

function GradientAccentsLR({ theme }) {
  return (
    <linearGradient
      x1="0"
      x2="100%"
      id={GradientAccentsLRId}
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor={theme.accent1}></stop>
      <stop stopColor={theme.accent2} offset="50%" stopOpacity={1}></stop>
      {/* <stop stopColor={theme.accent2} offset="100%" stopOpacity={0.5}></stop> */}
    </linearGradient>
  );
}

GradientAccentsLR.propTypes = {
  theme: PropTypes.shape({
    accent1: PropTypes.string,
    accent2: PropTypes.string,
  }),
};

export default withTheme(GradientAccentsLR);
