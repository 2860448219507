import React from "react";
import styled from "styled-components";
import ThemeToggle from "../../common/ThemeToggleButton";

const Nav = styled.nav`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  max-width: ${({ theme }) => theme.mainMaxWidth};
  padding: 0rem 1rem 0 0;
  width: 100%;
`;

export default function NavBar() {
  return (
    <Nav>
      <ThemeToggle />
    </Nav>
  );
}
