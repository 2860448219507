import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import blurPulseMinor from "./animations/blurPulseMinor";
import InterTTF from "../media/fonts/Inter-VariableFont.ttf";
import LondrinaOutlineTTF from "../media/fonts/LondrinaOutline-Regular.ttf";

const GlobalStyle = createGlobalStyle`
  
  // Normalize all styles with opinionated module normalize
  ${normalize}

  :root {
    color-scheme: dark light;
  }

  @font-face {
    font-family: "Inter";
    src: url(${InterTTF}) format("truetype");
    font-display: swap;

  }

  @font-face {
    font-family: "Londrina Outline";
    src: url(${LondrinaOutlineTTF}) format("truetype");
    font-display: swap;

  }

  body {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    min-width: 100vw;
    max-width: 100vw;
    box-sizing: border-box;
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.text.primary};
    font-family: ${({ theme }) => theme.text.fontFamilyBody};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // overflow: overlay is to accommodate scrollbar vw in chrome/webkit
    overflow: overlay;
  }

  h1, h2, h3 {
    text-align: center;

  }

  h2 {
    font-family: ${({ theme }) => theme.text.fontFamilyHeader};
  }

  h2 {
    text-transform: uppercase;
    font-size: clamp(2rem, calc(1.5rem + 3vmin), 4.5rem);
    font-weight: 600;
    letter-spacing: 0.3rem;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.accent2};
    animation: ${blurPulseMinor} 2.5s linear infinite alternate;
    }

  h3 {
    font-size: clamp(1.2rem, calc(1rem + 1vmin), 3rem);

  }

  h4 {
    margin: 0;
  }
  
  a {
    text-decoration: none;
    color: ${(props) => props.theme.text.primary};
  }

  p {
    padding: 0;
    margin: 0;
    line-height: 1.50em;
    font-size: 1rem;
  }

  section {
    margin: 0;
    padding: 2rem 0;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  ::selection {
      color: ${(props) => props.theme.backgroundColor};
      background-color: ${(props) => props.theme.accent1};
    }
`;

export default GlobalStyle;
