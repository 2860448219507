import { css, keyframes } from "styled-components";

const growPulseWProps = (props) => keyframes`
0% {
    transform: scale(${props.scaleMin}) translateZ(-1px);
  }
  100% {
    transform: scale(${props.scaleMax}) translateZ(-1px);
  }
`;

const growPulseBgCss = () => css`
  ${(props) => (props.theme.mode === "light" ? growPulseWProps(props) : "none")}
`;

export default growPulseBgCss;
