import PropTypes from "prop-types";
import * as React from "react";
import { memo } from "react";

export const modeDarkIconPath = `M12 4a1 1 0 0 1-1-1V1a1 1 0 0 1 2 0v2a1 1 0 0 1-1 1zM4.933 6.348a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414l-1.42-1.42a.999.999 0 1 0-1.414 1.414l1.42 1.42zM1 13h2a1 1 0 1 0 0-2H1a1 1 0 0 0 0 2zm19.486-8.072 3.221-3.221A.999.999 0 1 0 22.293.293l-3.221 3.221-1.42 1.42-2.19 2.19A5.955 5.955 0 0 0 12 6c-3.309 0-6 2.691-6 6 0 1.258.406 2.453 1.124 3.462l-2.105 2.105c-.026.021-.058.03-.083.055s-.033.056-.055.082l-1.368 1.368-.001.002-3.219 3.219a.999.999 0 1 0 1.414 1.414l3.987-3.987a10.03 10.03 0 0 0 6.332 2.262c5.103-.001 9.473-3.902 9.951-9.081a1 1 0 0 0-1.591-.896 5.96 5.96 0 0 1-7.037.06l5.717-5.716 1.42-1.421zm-.945 9.78c-1.21 3.337-4.564 5.587-8.257 5.238a8.019 8.019 0 0 1-4.165-1.651l4.802-4.802c.05.038.093.082.144.12a7.955 7.955 0 0 0 7.476 1.095zm-10.979-.684A3.968 3.968 0 0 1 8 12c0-2.206 1.794-4 4-4a3.98 3.98 0 0 1 2.025.561l-5.463 5.463z`;

const ModeSplitLightDarkIcon = ({
  pathFill = "currentColor",
  desc = null,
  alt = null,
  width = "100%",
  height = "100%",
  ...props
}) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    {desc || alt ? (
      <desc>
        {desc}
        {alt}
      </desc>
    ) : null}

    <path d={modeDarkIconPath} fill={pathFill} />
  </svg>
);

ModeSplitLightDarkIcon.propTypes = {
  alt: PropTypes.string,
  desc: PropTypes.string,
  height: PropTypes.string,
  pathFill: PropTypes.string,
  width: PropTypes.string,
};

const Memo = memo(ModeSplitLightDarkIcon);
export default Memo;
