import React from "react";
import { ExperienceList } from ".";
import { SectionResume, SubSectionResume } from "./SectionResume";

export default function ResumeEducation() {
  return (
    <SectionResume>
      <h2>
        <span>Education</span>
      </h2>
      <SubSectionResume>
        <h3>
          <span>Dartmouth College, Hanover, NH</span>
        </h3>
        <p>
          <span>October 2002 – May 2008</span>
        </p>
        <ExperienceList>
          <li>
            <span>
              Some coursework, including introductory computer science and
              programming.
            </span>
          </li>
        </ExperienceList>
      </SubSectionResume>
    </SectionResume>
  );
}
