import WiThemeDark from "../styles/WiThemeDark";
import WiThemeLight from "../styles/WiThemeLight";

export default function themeReducer(state, action) {
  switch (action.type) {
    case "goLight": {
      return { ...WiThemeLight };
    }
    case "goDark": {
      return { ...WiThemeDark };
    }
    case "goToggle": {
      return state.mode === "light" ? { ...WiThemeDark } : { ...WiThemeLight };
    }
    default: {
      throw new Error(`unexpected action.type in themeReducer`);
    }
  }
}
