import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { memo } from "react";

// const reactHookFormColor = "#ec5990";

const RhfDiv = styled.div`
  align-items: center;
  background-image: ${({ theme }) =>
    `linear-gradient(145deg, ${theme.accent1}, ${theme.accent2})`};
  border-radius: 20%;
  box-sizing: border-box;
  display: flex;
  height: 2rem;
  justify-content: center;
  padding: 1px;
  position: relative;
  width: 100%;
`;

const Svg = styled.svg.attrs((props) => ({
  width: props?.width ? props.width : "94%",
  height: props?.height ? props.height : "94%",
}))`
  box-sizing: border-box;
  display: grid;
  place-items: center;
  background-image: ${({ theme }) =>
    `linear-gradient(145deg, ${theme.accent1}, ${theme.accent2})`};
`;

const VisibleRect = styled.rect.attrs(() => ({
  ry: 20,
  rx: 20,
  width: 100,
  height: 100,
}))`
  fill: ${(props) => props.theme.backgroundColor};
`;

const MaskPathClipboard = styled.path.attrs(() => ({
  fill: "#000",
}))``;

const ReactHookFormLogoSimple = ({
  // pathFill = "currentColor",
  desc = null,
  alt = null,
  width,
  height,
  ...props
}) => (
  <RhfDiv>
    <Svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      {desc || alt ? (
        <desc>
          {desc}
          {alt}
        </desc>
      ) : null}
      <VisibleRect
        x="0"
        y="0"
        fillOpacity={"1"}
        mask={"url(#react-hook-form-logo-simple-mask)"}
      />
      <mask id="react-hook-form-logo-simple-mask">
        <g>
          <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
          <MaskPathClipboard d="M73.56,13.32H58.14a8.54,8.54,0,0,0-16.27,0H26.44a11,11,0,0,0-11,11V81.63a11,11,0,0,0,11,11H73.56a11,11,0,0,0,11-11V24.32A11,11,0,0,0,73.56,13.32Zm-30.92,2a1,1,0,0,0,1-.79,6.54,6.54,0,0,1,12.78,0,1,1,0,0,0,1,.79h5.38v6.55a3,3,0,0,1-3,3H40.25a3,3,0,0,1-3-3V15.32ZM82.56,81.63a9,9,0,0,1-9,9H26.44a9,9,0,0,1-9-9V24.32a9,9,0,0,1,9-9h8.81v6.55a5,5,0,0,0,5,5h19.5a5,5,0,0,0,5-5V15.32h8.81a9,9,0,0,1,9,9Z"></MaskPathClipboard>
          <MaskPathClipboard
            style={{ transform: "translateX(-25px)" }}
            d="M71.6,45.92H54a1,1,0,0,0,0,2H71.6a1,1,0,0,0,0-2Z"
          ></MaskPathClipboard>
          <MaskPathClipboard d="M71.6,45.92H54a1,1,0,0,0,0,2H71.6a1,1,0,0,0,0-2Z"></MaskPathClipboard>
          <MaskPathClipboard
            style={{ transform: "translateX(-25px)" }}
            d="M71.1,69.49H53.45a1,1,0,1,0,0,2H71.1a1,1,0,0,0,0-2Z"
          ></MaskPathClipboard>
          <MaskPathClipboard d="M71.1,69.49H53.45a1,1,0,1,0,0,2H71.1a1,1,0,0,0,0-2Z"></MaskPathClipboard>
        </g>
      </mask>
    </Svg>
  </RhfDiv>
);

ReactHookFormLogoSimple.propTypes = {
  alt: PropTypes.string,
  desc: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

const Memo = memo(ReactHookFormLogoSimple);
export default Memo;
