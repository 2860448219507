import React from "react";
import styled from "styled-components";
// import Cat from "./Cat";
import Map from "./Map";
import NameInLights from "./NameInLights";

const Board = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: clamp(300px, 40vmin, 600px);
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: clamp(300px, 60vmin, 720px);
  z-index: 1;
`;

const OldTown = styled.div`
  align-self: flex-end;
  background-color: transparent;
  bottom: clamp(0.8rem, calc(1px + 1vmin), 3.8rem);
  color: ${({ theme }) => theme.accent1};
  display: flex;
  flex-flow: column nowrap;
  font-family: "Londrina Outline", cursive;
  font-size: clamp(1.6rem, calc(1rem + 1.3vmin), 4rem);
  font-weight: 600;
  letter-spacing: 0.3vmin;
  line-height: 1;
  margin: 0 0 0 clamp(50px, 7vmin, 720px);
  position: absolute;
  text-transform: uppercase;
  width: fit-content;
  z-index: 2;
`;

export default function Billboard() {
  return (
    <Board>
      <NameInLights />
      <Map />
      {/* <Cat /> */}
      <OldTown>
        <span>Front-End</span>
        <span>Developer</span>
      </OldTown>
    </Board>
  );
}
