/* FONT CONSIDERATIONS */

/* 
// Actual Portland sign is based on old-timey Brush Script, which has relatives in ATF Brush etc.
// TODO: Find closer relative to Brush among open-source foundries

// Candidate fonts
- Mr Stalwart Pro by sudtipos.com
- mika-melvas/sanelma
- font-family: 'Bungee Inline', cursive;
- font-family: 'Codystar', cursive;
- font-family: 'Cookie', cursive;
- font-family: 'Dancing Script', cursive;
- font-family: 'Kolker Brush', cursive;
- font-family: 'Londrina Outline', cursive;
- font-family: 'Pacifico', cursive;
font-family: 'Satisfy', cursive; <-- Used in Portland, OR style hero sign
- font-family: 'Train One', cursive;
- font-family: 'Yellowtail', cursive;
*/

/* DISPLAY */
//near-white background
// const backgroundColor = `#F1F1F9`;
const backgroundColor = `#f7f7f7`; // UW-Madison's near-white background color

// accent color 1
// const accentColor1 = `#004955`;
// const accentColor1 = `#127e42`;
// const accentColor1 = `#16a054`; // Light Portland green
// const accentColor1 = `#0f703b`; // Mid Portland green
// const accentColor1 = `#c5050c`; // UW–Madison’s primary cardinal red color.
const accentColor1 = `#9b0000`; // UW–Madison’s accent dark cardinal red color.

// accent color 2
// const accentColor2 = `#d73a49`;
// const accentColor2 = `#e1ad00`; // billboard yellow
// const accentColor2 = `#127e42`; // Light Portland green
// const accentColor2 = `#44608A`; // off-billboard blue/gray
// const accentColor2 = `#2b65bd`; // bright blue
// const accentColor2 = `#888`; // gray
// const accentColor2 = `#703B0F`; // brown complementary to Mid portland green
// const accentColor2 = `#00000088`; // black to gray via opacity
// const accentColor2 = `#818181`; // black to gray via rgb
const accentColor2 = `#646569`; // UW-Madison's gray accent color

/* TEXT */
// for main body text
const bodyTextColor = `#001111`;

// for links and other accented text
// const bodyTextAccentColor = `#c5050c`; // UW–Madison’s primary cardinal red color.
const bodyTextAccentColor = `#9b0000`; // UW–Madison’s accent dark cardinal red color.

// const bodyTextAccentColor = `#888`; // Mid Gray

const WiThemeDay = {
  mode: "light",
  primary: bodyTextColor,
  accent1: accentColor1,
  accent2: accentColor2,
  backgroundColor: backgroundColor,
  mainMaxWidth: "690px",
  text: {
    primary: bodyTextColor,
    secondary: bodyTextAccentColor,
    accent: bodyTextAccentColor,
    fontFamilyBody: `'Inter', 'Open Sans',
    sans-serif`,
    fontFamilyHeader: `"Londrina Outline", cursive`,
    fontFamilyHero: `"Londrina Outline", cursive`,
  },
  gradients: {
    accents145: `linear-gradient(145deg, ${accentColor1}, ${accentColor2})`,
  },
};

export default WiThemeDay;
