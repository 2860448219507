import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import StyledLink from "../common/Link";

const LinkName = styled.span`
  display: inline-block;
  margin: 0;
  padding: 0;
`;

const LinkListItem = ({ className, children, name, href }) => {
  return (
    <li className={className}>
      <StyledLink href={href}>
        {name && <LinkName>{name}</LinkName>}
        {children}
      </StyledLink>
    </li>
  );
};

LinkListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  name: PropTypes.string,
};

export default LinkListItem;
