import PropTypes from "prop-types";
import * as React from "react";
import { memo } from "react";
import styled from "styled-components";

const MText = styled.text`
  font-family: "Inter", sans-serif;
  font-size: 0.8rem;
  font-weight: 800;
`;

const ModalityLogo = ({
  pathFill = "currentColor",
  desc = null,
  alt = null,
  width = "100%",
  height = "100%",
  ...props
}) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...props}
  >
    {desc || alt ? (
      <desc>
        {desc}
        {alt}
      </desc>
    ) : null}
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      fillOpacity={"1"}
      fill={pathFill}
      mask={"url(#modality-m-mask)"}
    />

    <mask id="modality-m-mask">
      <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
      <MText
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline={"central"}
        textLength={"50%"}
        fill="#000"
      >
        M
      </MText>
    </mask>
  </svg>
);

ModalityLogo.propTypes = {
  alt: PropTypes.string,
  desc: PropTypes.string,
  height: PropTypes.string,
  pathFill: PropTypes.string,
  width: PropTypes.string,
};

const Memo = memo(ModalityLogo);
export default Memo;
