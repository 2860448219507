import React from "react";
import styled from "styled-components";
import Link from "../Link";
import SvgDocumentText from "../../../media/svgr/SvgDocumentText";
import resumePdf from "../../../media/matthieu-pierce-resume.pdf";
import { GradientAccents45Url } from "../../../styles/SvgDefs/GradientAccents45";

const DivResumeStack = styled.div`
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  max-height: 100px;
  max-width: 100px;
  padding: 2px;
  position: relative;
  transform: scale(1);
  transition: transform 250ms ease-in-out;
  width: 100%;
  ::before {
    background-color: ${({ theme }) => `${theme.text.primary}20`};
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform 220ms ease-in-out;
    width: 100%;
    z-index: -1;
  }
  &:hover {
    ::before {
      transform: scale(1.1);
    }
  }
  &:active {
    ::before {
      transform: scale(1.2);
    }
  }
`;

const LinkForResumeDoc = styled(Link)`
  height: 50%;
`;

export default function ResumePdfButton() {
  return (
    <DivResumeStack>
      <LinkForResumeDoc
        href={resumePdf}
        without={"true"}
        rel="noopener noreferrer"
        target="_blank"
      >
        <SvgDocumentText
          options={{
            height: "100%",
            width: "100%",
            fill: GradientAccents45Url,
          }}
        />
      </LinkForResumeDoc>
      <Link
        href={resumePdf}
        without={"true"}
        rel="noopener noreferrer"
        target="_blank"
      >
        Resume (PDF)
      </Link>
    </DivResumeStack>
  );
}
