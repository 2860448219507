import React from "react";
import styled from "styled-components";
import ContactInformation from "./ContactInformation";
import Profile from "../Profile";
import PortfolioBlock from "../PortfolioBlock";
import { SectionResume } from "./SectionResume";
import ResumeSkills from "./ResumeSkills";
import ResumeCertifications from "./ResumeCertifications";
import WorkExperience from "./WorkExperience";
import ResumeEducation from "./Education";

const MainResume = styled.main`
  align-items: start;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  max-width: 100%;
  width: ${({ theme }) => theme.mainMaxWidth};
  h2 {
    font-size: clamp(1.2rem, calc(1.2rem + 2vw), 2.8rem);
    margin: 0;
  }
  h3 {
    font-size: clamp(1.2rem, calc(1.1rem + 0.2vw), 2rem);
    margin: 0;
  }
`;

export const ExperienceList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0.4rem 0 0 0;
  li:not(:last-of-type) {
    padding: 0 0 0.8rem 0;
  }
`;

export default function ResumePage() {
  return (
    <MainResume>
      {/* <h1>Resume</h1> */}

      <SectionResume>
        <h2>Summary</h2>
        <Profile text="(he/him) is a Front End developer with business process experience." />
      </SectionResume>

      <ResumeCertifications />

      <ResumeSkills />

      <ResumeEducation />

      <PortfolioBlock />

      {/* <ResumeProjects /> */}

      <WorkExperience />

      <ContactInformation />
    </MainResume>
  );
}
