import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import PortraitPic from "../common/PortraitPic";
import ResumePdfButton from "../common/ResumePdf";

const SectionProfile = styled.section`
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 100px 100px;
  justify-items: center;
  max-width: 100%;
  padding: 0;
  text-align: center;
`;

const DivSummary = styled.div`
  display: block;
  gap: 0.4ch;
  width: 100%;
`;
const H1Name = styled.h1`
  display: inline-block;
  font-family: ${({ theme }) => theme.text.fontFamilyBody};
  font-size: inherit;
  margin: 0;
`;

export default function Profile({ text }) {
  const [profileText] = useState(() =>
    text ? text : `(he/him) is a front-end developer in Madison, WI.`
  );

  return (
    <SectionProfile>
      <DivSummary>
        <H1Name>Matthieu Pierce</H1Name>
        <span> {profileText}</span>
      </DivSummary>
      <PortraitPic />
      <ResumePdfButton />
    </SectionProfile>
  );
}

Profile.propTypes = {
  text: PropTypes.string,
};
