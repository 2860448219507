import React from "react";
import styled from "styled-components";
import profileImgJpg from "../../../media/portrait/matthieu-pierce-portrait-360.jpg";
import profileImgWebp from "../../../media/portrait/matthieu-pierce-portrait-360.webp";
import profileImgAvif from "../../../media/portrait/matthieu-pierce-portrait-360.avif";
const ImgRoundWrap = styled.div`
  border-radius: 50%;
  box-sizing: border-box;
  height: 100px;
  overflow: hidden;
  width: 100px;
`;
const PortraitRound = styled.picture`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  img,
  source {
    height: 100%;
    width: 100%;
  }
`;

export default function PortraitPic() {
  return (
    <ImgRoundWrap>
      <PortraitRound>
        <source srcSet={profileImgAvif} type="image/avif" />
        <source srcSet={profileImgWebp} type="image/webp" />
        <img src={profileImgJpg} alt="portrait of Matthieu Pierce" />
      </PortraitRound>
    </ImgRoundWrap>
  );
}
