import styled from "styled-components";

export const SectionResume = styled.section`
  display: grid;
  gap: 0.4rem 2rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 320px));
  justify-content: center;
  justify-items: center;
  padding: 0 0 2rem 0;
  text-align: center;
  width: 100%;
  h2 {
    grid-column: 1/-1;
  }
  h3 {
    width: 100%;
  }
  & ${SubSectionResume} section:nth-of-type(2n + 1):last-child {
    grid-column: 1/-1;
  }
`;

export const SubSectionResume = styled.section`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  max-width: 400px;
  padding: 0 0 1rem;
  width: 100%;
`;
