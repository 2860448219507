/* FONT CONSIDERATIONS */

/* 
// Actual Portland sign is based on old-timey Brush Script, which has relatives in ATF Brush etc.
// TODO: Find closer relative to Brush among open-source foundries

// Candidate fonts
- Mr Stalwart Pro by sudtipos.com
- mika-melvas/sanelma
- font-family: 'Bungee Inline', cursive;
- font-family: 'Codystar', cursive;
- font-family: 'Cookie', cursive;
- font-family: 'Dancing Script', cursive;
- font-family: 'Kolker Brush', cursive;
- font-family: 'Londrina Outline', cursive;
- font-family: 'Pacifico', cursive;
font-family: 'Satisfy', cursive; <-- Used in Portland, OR style hero sign
- font-family: 'Train One', cursive;
- font-family: 'Yellowtail', cursive;
*/

//near-black background
const backgroundColor = `#001111`;

// near-white body text
const bodyTextColor = `#F1F1F9`;

// accent color 1
// const accentColor1 = `#004955`;
// const accentColor1 = `#127e42`;
const accentColor1 = `#16a054`; // Portland green

// accent color 2
// const accentColor2 = `#d73a49`;
const accentColor2 = `#e1ad00`; // billboard yellow

// light highlight color
// const highlightColor = "#00495544";

const WiThemeNight = {
  mode: "dark",
  primary: bodyTextColor,
  accent1: accentColor1,
  accent2: accentColor2,
  backgroundColor: backgroundColor,
  mainMaxWidth: "690px",

  text: {
    primary: bodyTextColor,
    secondary: accentColor1,
    accent: accentColor1,
    fontFamilyBody: `'Inter', 'Open Sans',
    sans-serif`,
    fontFamilyHeader: `"Londrina Outline", cursive`,
    fontFamilyHero: `"Londrina Outline", cursive`,
  },
  gradients: {
    accents145: `linear-gradient(145deg, ${accentColor1}, ${accentColor2})`,
  },
};

export default WiThemeNight;
