import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { useMetaThemeContext } from "../../../contexts/MetaThemeContext";
import ModeDarkIcon from "../../../media/icons/ModeDarkIcon";
import ModeLightIcon from "../../../media/icons/ModeLightIcon";
import growPulseBg from "../../../styles/animations/growPulseBg";
import growPulseBgCss from "../../../styles/animations/growPulseBgCss";

const ToggleButton = styled.button.attrs(() => ({
  buttonHW: "2.2rem",
  borderW: "1px",
  scaleMin: 1.2,
  scaleMax: 1.35,
}))`
  display: grid;
  position: relative;
  place-items: center;
  box-sizing: border-box;
  border: ${({ theme, borderW }) =>
    theme.mode === "light"
      ? `${borderW} solid ${theme.accent2}`
      : `${borderW} solid transparent`};
  border: none;
  border-radius: 50%;
  width: ${({ buttonHW }) => buttonHW};
  height: ${({ buttonHW }) => buttonHW};
  transform-origin: center;
  transform: scale(1);
  z-index: 2;
  transform-style: preserve-3d;
  transition: transform 150ms ease-in;
  color: ${({ theme }) => theme.accent2};
  background-color: ${({ theme }) => theme.backgroundColor};
  ::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    border: ${({ borderW }) => borderW} solid transparent;
    border: none;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.accent2 + `44`};
    background-color: red;
    background: ${({ theme }) =>
      `radial-gradient(circle at center, ${theme.accent2}, transparent 100%)`};
    filter: blur(4px);
    width: ${({ buttonHW }) => buttonHW};
    height: ${({ buttonHW }) => buttonHW};
    z-index: -1;
    transform-origin: center;

    transform: scale(1.1) translateZ(-1px);
    animation: ${growPulseBgCss} 1s ease-in-out infinite alternate;
  }
  &:active {
    animation: ${growPulseBg} 150ms ease-in-out;
  }
  &:hover {
    transform: scale(1.15);
    cursor: pointer;
    ::after {
      transform: scale(1.1) translateZ(-1px);
      /* animation: none; */
    }
  }
`;

const ChildrenWrapper = styled.div`
  height: auto;
  transform: scale(1);
  transition: transform 150ms ease-in;
  /* z-index: 5; */
  width: auto;
  &:hover,
  &:active {
    cursor: pointer;
    transform: scale(1.15);
  }
`;

export default function ThemeToggleButton(props) {
  const { isDark, toggleThemeMode, theme } = useMetaThemeContext();

  if (!props?.children)
    return (
      <ToggleButton
        onClick={toggleThemeMode}
        isDark={isDark}
        aria-label="Toggle Theme"
      >
        {isDark && <ModeLightIcon pathFill={theme.accent2} width={"100%"} />}
        {!isDark && <ModeDarkIcon pathFill={theme.accent2} width={"100%"} />}
      </ToggleButton>
    );

  const children = React.Children.map(props.children, (child) => {
    return React.cloneElement(child, { isDark });
  });

  return (
    <ChildrenWrapper {...props} onClick={toggleThemeMode} isDark={isDark}>
      {children}
    </ChildrenWrapper>
  );
}

ThemeToggleButton.propTypes = {
  children: PropTypes.node,
};
