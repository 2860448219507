import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row wrap;
  justify-content: space-evenly;
  justify-items: center;
  width: 100%;
`;

export default function IconsRow({ children }) {
  return <Row>{children}</Row>;
}

IconsRow.propTypes = {
  children: PropTypes.node,
};
