import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const Div = styled.div.attrs(({ height, width }) => ({
  height: height || "2rem",
  width: width || "2rem",
}))`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default function IconWrap({ children, ...props }) {
  return (
    <Div {...props}>
      {React.Children.map(children, (child) => (
        <React.Fragment>{child}</React.Fragment>
      ))}
    </Div>
  );
}

IconWrap.propTypes = {
  children: PropTypes.node,
};
