import React from "react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";

export const GradientDefOneId = `gradient-def-one`;
export const GradientDefOneUrl = `url(#${GradientDefOneId})`;

const GradientDefOne = ({ theme }) => {
  return (
    <svg
      width="0px"
      height="0px"
      viewBox="0 0 0 0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id={GradientDefOneId}
          x1="0"
          y1="0"
          x2="24"
          y2="24"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.accent1} />
          <stop offset="100%" stopColor={theme.accent2} />
        </linearGradient>
      </defs>
    </svg>
  );
};

GradientDefOne.propTypes = {
  theme: PropTypes.object,
};
export default withTheme(GradientDefOne);
