import React from "react";
import styled from "styled-components";
import Link from "../common/Link";
import { SectionResume, SubSectionResume } from "./SectionResume";
import ElsewhereLinks from "../ElsewhereLinks";

const ElsewhereLinksResume = styled(ElsewhereLinks)`
  grid-column: 1/-1;
`;

export default function ContactInformation() {
  return (
    <SectionResume>
      <h2>Contact Information</h2>
      <SubSectionResume>
        <h3>Location</h3>
        {/* <div>
            <span>Madison, WI</span>
          </div> */}
        <div>Madison, WI</div>
      </SubSectionResume>
      <SubSectionResume>
        <Link href={"tel:+18022814383"}>
          <h3>Call</h3>
          <span>+1 802-281-4383</span>
        </Link>
        <div>Texts welcome</div>
      </SubSectionResume>
      {/* <SubSectionResume>
        <Link href={"mailto:me@matthieupierce.com"}>
          <h3>Email</h3>
          <span>me@matthieupierce.com</span>
        </Link>
      </SubSectionResume>
      <SubSectionResume>
        <Link href={"https://linkedin.com/in/matthieupierce"}>
          <h3>LinkedIn</h3>
          <span>linkedin.com/in/matthieupierce</span>
        </Link>
      </SubSectionResume>
      <SubSectionResume>
        <Link href={"https://github.com/matthieupierce"}>
          <h3>Github</h3>
          <span>github.com/matthieupierce</span>
        </Link>
      </SubSectionResume>
      <SubSectionResume>
        <Link href={"https://matthieupierce.com"}>
          <h3>Website</h3>
          <span>https://matthieupierce.com</span>
        </Link>
      </SubSectionResume> */}
      {/* <SubSectionResume>
          <h3>Links</h3>
          <ul>
            <li>
              <Link href={"https://matthieupierce.com"}>Portfolio Website</Link>
            </li>
            <li>
              <Link href={"https://github.com/matthieupierce"}>GitHub</Link>
            </li>
            <li>
              <Link href={"https://linkedin.com/in/matthieupierce"}>
                LinkedIn
              </Link>
            </li>
          </ul>
        </SubSectionResume> */}
      {/* <ul>
          <li>
            <strong>Location:</strong> Madison, WI
          </li>
          <li>Email: me@matthieupierce.com</li>
          <li>Website: https://matthieupierce.com</li>
          <li>Mobile: +1 802-281-4383</li>
          <li>LinkedIn: https://linkedin.com/in/matthieupierce</li>
          <li>GitHub: https://github.com/matthieupierce</li>
        </ul> */}
      <ElsewhereLinksResume
        showHeading={false}
        iconHeight={"1.4rem"}
        heading="Contact Information"
      />
    </SectionResume>
  );
}
