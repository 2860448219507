import React from "react";
import Link from "../common/Link";
import { SectionResume, SubSectionResume } from "./SectionResume";

export default function ResumeCertifications() {
  return (
    <SectionResume>
      <h2>Certifications</h2>

      <SubSectionResume>
        <h3>
          <Link
            href={
              "https://www.freecodecamp.org/certification/fcc5f9a5342-1b4d-42d5-9768-23e5861902ca/responsive-web-design"
            }
          >
            Responsive Web Design
          </Link>
        </h3>
        <div>FreeCodeCamp, Dec 2020 – Jan 2021</div>
      </SubSectionResume>

      <SubSectionResume>
        <Link
          href={
            "https://www.freecodecamp.org/certification/fcc5f9a5342-1b4d-42d5-9768-23e5861902ca/javascript-algorithms-and-data-structures"
          }
        >
          <h3>Javascript Algorithms & Data Structures</h3>
        </Link>
        <div>FreeCodeCamp, January 2021</div>
      </SubSectionResume>

      <SubSectionResume>
        <Link
          href={
            "https://www.freecodecamp.org/certification/fcc5f9a5342-1b4d-42d5-9768-23e5861902ca/front-end-development-libraries"
          }
        >
          <h3>Front End Development</h3>
        </Link>

        <div>FreeCodeCamp, February 2021</div>
      </SubSectionResume>

      <SubSectionResume>
        <Link
          href={
            "https://www.freecodecamp.org/certification/fcc5f9a5342-1b4d-42d5-9768-23e5861902ca/back-end-development-and-apis"
          }
        >
          <h3> Back End Development and APIs</h3>
        </Link>

        <div>FreeCodeCamp, March 2021</div>
      </SubSectionResume>

      <SubSectionResume>
        <Link
          href={
            "https://www.freecodecamp.org/certification/fcc5f9a5342-1b4d-42d5-9768-23e5861902ca/data-visualization"
          }
        >
          <h3>Data Visualization (D3)</h3>
        </Link>

        <div>FreeCodeCamp, Mar 2021 – Apr 2021</div>
      </SubSectionResume>
    </SectionResume>
  );
}
