import React from "react";
import styled from "styled-components";
import { SectionResume } from "./SectionResume";

const JobSection = styled(SectionResume)`
  gap: 0;
  grid-template-columns: 1fr;
  h3 {
    max-width: 440px;
  }
`;

const JobSubSection = styled.section`
  align-items: center;
  display: flex;
  flex-flow: column;
  padding: 1rem 0 1rem;
`;

export const WorkExperienceList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  li {
    display: inline-block;
    max-width: 300px;
  }
  justify-content: space-between;
  list-style-type: none;
  margin: 0;
  padding: 0.4rem 0 0 0;
  @media (max-width: 400px) {
    justify-content: space-around;
  }
`;

export default function WorkExperience() {
  return (
    <JobSection>
      <h2>Work Experience</h2>

      <JobSubSection>
        <h3>Full-Stack Developer</h3>
        <h4>Self-Employed, Portland, OR</h4>
        <p>May 2022 – November 2022</p>
        <WorkExperienceList>
          <li>
            Front end development and back end engineering of websites for small
            businesses, using React and Node.
          </li>
        </WorkExperienceList>
      </JobSubSection>

      <JobSubSection>
        <h3>Technical Documentation Consultant</h3>
        <h4>Auxon Corporation, Portland, OR</h4>
        <p>May 2020 – January 2021</p>
        <WorkExperienceList>
          <li>
            Introduced strategic structure, CSS styling, and content into the
            VuePress docs of a complex product.
          </li>
          <li>
            Created planning and business development materials for leadership.
          </li>
          <li>
            Engaged Kanban tools in GitHub to coordinate the workflow of
            internal and external team members.
          </li>
        </WorkExperienceList>
      </JobSubSection>

      <JobSubSection>
        <h3>Systems Consultant / Partner Advocate</h3>
        <h4>Stash Hotel Rewards, Portland, OR</h4>
        <p>January 2016 – October 2019</p>
        <WorkExperienceList>
          <li>
            Integrated the diverse systems of 150 independent hotels into a
            cohesive loyalty program.
          </li>
          <li>
            Recovered affiliate revenue from more than 30 hotels with
            previously-dysfunctional data flows.
          </li>
          <li>
            Facilitated integrations with booking, management, reporting
            databases, and marketing vendors.
          </li>
          <li>
            Streamlined human processes, drafted best practices, and resolved
            outages.
          </li>
          <li>
            Promoted and integrated Stash initiatives into partners{`'`}{" "}
            business strategies.
          </li>
        </WorkExperienceList>
      </JobSubSection>

      <JobSubSection>
        <h3>
          Chief Technology Officer / Revenue Manager / Assistant General Manager
        </h3>
        <h4>Shadyside Inn All Suites Hotel, Pittsburgh, PA</h4>
        <p>November 2009 – December 2015</p>
        <WorkExperienceList>
          <li>
            Grew from technical administration to leadership, wearing 12 hats
            over six years.
          </li>
          <li>
            Catalyzed change by building trust and advocating for stakeholder
            needs across the business.
          </li>
          <li>
            Progressively updated human processes to surpass national brands,
            spanning guest experience, revenue management, staff support,
            security, housekeeping, marketing, and sales.
          </li>

          <li>
            Researched, proposed, and implemented upgrades to nearly every
            digital platform and hardware system throughout hotel operations,
            from websites and digital displays to physical access.
          </li>
          <li>
            Led revenue management decision-making for $2M+/year in revenue,
            with 5 years of consecutive growth.
          </li>
        </WorkExperienceList>
      </JobSubSection>
    </JobSection>
  );
}
