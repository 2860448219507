import { keyframes } from "styled-components";

const growPulseBg = keyframes`
0% {
    transform: scale(1.1) translateZ(-1px);
  }
  100% {
    transform: scale(1.3) translateZ(-1px);
  }
`;

export default growPulseBg;
