import React from "react";
import styled from "styled-components";
import { SectionResume, SubSectionResume } from "./SectionResume";
const SkillList = styled.ul`
  align-items: center;
  display: grid;
  gap: 0.2rem 1rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  justify-content: center;
  list-style-type: none;
  padding: 0;
  width: 100%;
  & li {
    display: inline-block;
    width: auto;
  }
`;

export default function ResumeSkills() {
  return (
    <SectionResume>
      <h2>Skills</h2>

      <SubSectionResume>
        <h3> Front-End Development</h3>
        <SkillList>
          <li>React</li>
          <li>JavaScript</li>
          <li>HTML5</li>
          <li>CSS</li>
          <li>D3</li>
          <li>Material UI</li>
          <li>Bootstrap</li>
          <li>Styled Components</li>
          <li>Redux</li>{" "}
        </SkillList>
      </SubSectionResume>

      <SubSectionResume>
        <h3>Back-End Development</h3>
        <SkillList>
          <li>Node</li>
          <li>Express</li>
          <li>MongoDB</li>
          <li>REST APIs</li>
          <li>PostgreSQL</li>
          <li>JSON</li>
          <li>JWT authentication</li>
        </SkillList>
      </SubSectionResume>

      <SubSectionResume>
        <h3>Developer Tools</h3>
        <SkillList>
          <li>Git </li>
          <li> GitHub</li>
          <li> VSCode</li>
          <li> NPM</li>
          <li> Postman</li>
          <li> Webpack</li>
          <li> Vite</li>
          <li> Bash</li>
          <li> Windows</li>
          <li> Linux</li>
        </SkillList>
      </SubSectionResume>

      <SubSectionResume>
        <h3>Professional Skills</h3>
        <SkillList>
          <li>Process Review</li>
          <li>Consulting</li>
          <li>Systems Integration</li>
          <li>Creative Writing</li>
          <li>Technical Writing</li>
          <li>Agile</li>
        </SkillList>
      </SubSectionResume>
    </SectionResume>
  );
}
