import React from "react";
import styled from "styled-components";
import blurPulse from "../../../styles/animations/blurPulse";
import MapOfWisconsin from "../../../media/svgr/MapOfWisconsin";

const MapWrap = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  max-width: 100vw;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const MapOfWisconsinStyled = styled(MapOfWisconsin).attrs(() => ({
  strokeWidth: 4,
  blur: "1px",
}))`
  width: ${({ strokeWidth, blur }) =>
    `calc(100% - ${strokeWidth}px - ${blur} - 1px)`};
  height: ${({ strokeWidth, blur }) =>
    `calc(100% - ${strokeWidth}px - ${blur} - 1px)`};
  box-sizing: border-box;
  max-width: 100%;
  stroke-width: ${({ strokeWidth }) => strokeWidth};
  color: ${({ theme }) => `${theme.accent2}AA`};
  filter: ${({ blur }) => `blur(${blur})`};
  animation: ${blurPulse} 1.5s linear infinite alternate;
`;

export default function Map() {
  return (
    <MapWrap>
      <MapOfWisconsinStyled desc="Outline of Wisconsin State" />
    </MapWrap>
  );
}
