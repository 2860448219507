import styled from "styled-components";

const Link = styled.a.attrs((props) => ({
  target: props?.target ? props.target : "_blank",
  rel: props?.rel ? props.rel : "noreferrer",
}))`
  display: inline-grid;
  align-items: center;
  color: ${({ theme }) => theme.text.secondary};
  transition: color 400ms ease-in-out, text-decoration 250ms ease-in-out,
    text-underline-offset 250ms ease-in-out;
  width: auto;

  :hover,
  :focus {
    color: ${({ theme }) => theme.text.primary};
    text-decoration: ${({ theme }) => theme.accent2} solid underline 0.15em;
    text-underline-offset: 0.2em;
  }
`;

export default Link;
